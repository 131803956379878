.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.verification-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.verification-modal .modal-dialog {
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: 1.75rem auto;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  outline: 0;
}

.verification-modal-header {
  background-color: #000000;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0.75rem 1.5rem;
}

.verification-modal-body {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.verification-form {
  flex: 1;
  margin-right: 1.5rem;
}

.verification-message {
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
}

.verification-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 0.9rem;
}

.error-message {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  display: block;
}

.timer-container {
  flex: 0 0 40%;
  text-align: center;
  padding-top: 0.25rem;
}

.timer {
  font-size: 0.85rem;
  color: #dc3545;
  margin-bottom: 0.25rem;
}

.timer.expired {
  font-weight: bold;
}

.resend-link {
  font-size: 0.875rem;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.resend-link:hover:not(.disabled) {
  text-decoration: underline;
}

.resend-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.verification-modal-footer {
  padding: 0.75rem 1.5rem;
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: flex-end;
}

.submit-button,
.cancel-button {
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  margin-left: 0.5rem;
}

.submit-button {
  background-color: #007bff;
  border-color: #007bff;
}

.submit-button:hover:not(:disabled) {
  background-color: #0056b3;
  border-color: #0056b3;
}

.cancel-button {
  background-color: #6c757d;
  border-color: #6c757d;
}

.cancel-button:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

@media (max-width: 576px) {
  .verification-modal-body {
    flex-direction: column;
  }

  .verification-form {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .timer-container {
    width: 100%;
  }
}

.message-container {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.message-container.error {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
}

.message-container.success {
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
}

.message-text {
  font-size: 14px;
}

.message-container.error .message-text {
  color: #d32f2f;
}

.message-container.success .message-text {
  color: #388e3c;
}
