.session-card {
  transition: transform 0.2s;
  height: 100%;
  width: 100%;
  padding: 0;
}

.session-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.session-image {
  width: 100%;
  height: 205px;
  object-fit: cover;
  object-position: center;
  margin: 0;
  display: block;
}

@media (max-width:1400px){
    .session-image{
        height: 180px;
    }
}

@media (max-width: 1202px) {
  .session-image {
    height: 220px;
  }
}

@media (max-width: 991px) {
    .session-image {
      height: 352px;
     
    }
}
@media (max-width: 768px) {
  .session-image {
    height: auto;
  }
}

@media (max-width: 550px) {
  .session-image {
    height: 255px;
  }
}

@media (max-width: 525px) {
  .session-image {
    height: 235px;
  }
}

@media (max-width: 485px) {
  .session-image {
    height: 215px;
  }
}

@media (max-width: 450px) {
  .session-image {
    height: 200px;
  }
}

@media (max-width: 420px) {
  .session-image {
    height: 195px;
  }
}

@media (max-width: 410px) {
  .session-image {
    height: 185px;
  }
}

@media (max-width: 395px) {
  .session-image {
    height: 175px;
  }
}

@media (max-width: 375px) {
  .session-image {
    height: 165px;
  }
}

@media (max-width: 355px) {
  .session-image {
    height: 160px;
  }
}

@media (max-width: 340px) {
  .session-image {
    height: 155px;
  }
}


@media (max-width: 335px) {
  .session-image {
    height: 150px;
  }
}

.session-title {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  /* Ensure title doesn't wrap to more than 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.session-description {
  /* Limit to 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1rem;
  line-height: 1.5;
  height: 1.5em; /* Exactly 2 lines */
}

.session-details {
  font-size: 0.9rem;
  color: #666;
}

.session-details div {
  margin-bottom: 0.25rem;
}
