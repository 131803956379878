.session-info-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  .session-tabs {
    margin-bottom: 30px;
    justify-content: center;
  }
  
  #session-tabs .nav-pills .nav-link {
    background-color: #0056b3;
    color: white;
    font-size: 1.1rem;
    margin-right: 10px;
    transition: all 0.3s ease;
  }
  
  #session-tabs .nav-pills .nav-link.active {
    background-color: #007bff;
  }
  
  .tab-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .lecture-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .lecture-item {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-bottom: 1px solid #eee;
    gap: 1rem;
  }
  
  .lecture-item:last-child {
    border-bottom: none; /* Remove border for the last item */
  }
  
  .lecture-item:hover {
    transform: scale(1.02);
  }
  
  .lecture-video-container {
    width: 100%;
    position: relative;
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .lecture-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #000; /* Add black background */
    object-fit: contain; /* Ensure video fits properly */
  }
  
  .lecture-info {
    padding: 0.5rem 0;
  }
  
  .lecture-title {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  
  /* Make videos responsive on smaller screens */
  @media (max-width: 768px) {
    .lecture-item {
      padding: 1rem;
    }
    
    .lecture-video-container {
      padding-top: 75%; /* 4:3 Aspect Ratio for mobile */
    }
  }
  
  .add-button {
    background-color: #007bff;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  
  .pagination-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .infinite-scroll-component {
    overflow: visible !important; /* Override default scrolling behavior */
  }
  
  .spinner-border {
    width: 2rem;
    height: 2rem;
  }
  
  .video-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f9fa;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .thumbnail {
    width: 100%; /* Adjust as needed */
    height: 100%; /* Maintain aspect ratio */
    cursor: pointer; /* Indicate that it's clickable */
  }
  
  .video-placeholder {
    width: 100%; /* Adjust as needed */
    height: 200px; /* Set a fixed height for the placeholder */
    background-color: #f0f0f0; /* Light gray background */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer; /* Indicate that it's clickable */
  }
  
  .play-button {
    position: absolute;
    font-size: 2rem; /* Adjust size as needed */
    color: white; /* Change color as needed */
  }
  
  .lecture-title-overlay {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white; /* Change color as needed */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 5px;
  }
  