.session-dropdown-container {
    margin-bottom: 15px;
  }
  
  .session-dropdown-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .session-dropdown-select {
    width: 100%;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .session-dropdown-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .session-dropdown-loading,
  .session-dropdown-error {
    padding: 10px;
    border-radius: 4px;
    font-style: italic;
  }
  
  .session-dropdown-loading {
    background-color: #e9ecef;
    color: #495057;
  }
  
  .session-dropdown-error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
  }