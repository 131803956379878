@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
  background-color: #f4f4f4;
}

.login-main {
  display: flex;
  height: 100vh;
}

.login-left {
  flex-grow: 1;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-right {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-logo {
  align-self: center;
}

.login-logo img {
  width: 75px;
  height: 75px;
  margin-bottom: 75px;
}

.login-center {
  text-align: center;
}

.login-center h2 {
  font-size: 40px;
}

.login-center p {
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 40px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

form input[type="password"],
form input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  box-sizing: border-box;
  font-size: 15px;
}

.pass-input-div {
  position: relative;
}

.pass-input-div svg {
  position: absolute;
  right: 10px;
  bottom: 35px;
  cursor: pointer;
}

form button[type="submit"] {
  width: 75%;
  padding: 5px;
  border: 2px solid black;
  border-radius: 30px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
  background-color: black;
  color: white;
  margin-top: 10px;
}

form button[type="submit"]:hover {
  background-color: white;
  color: black;
  border: 2px solid black;
}

.login-center-options {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.forgot-pass-link {
  text-decoration: none;
  font-size: 15px;
  color: black;
}

.forgot-pass-link:hover {
  text-decoration: underline;
}

.error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 2px;
  border-radius: 4px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 12px;
  animation: fadeIn 0.5s, fadeOut 0.5s 4.5s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@media (max-width: 900px) {
  .login-left {
    display: none;
  }
}

@media (max-width: 370px) {
  .login-center-options {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .login-center-buttons {
    margin-top: 20px;
  }

  .login-center h2 {
    font-size: 3rem;
  }

  .login-center p {
    font-size: 1.5rem;
  }
}
