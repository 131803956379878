.multi-file-upload-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .multi-file-upload-header {
    color: #333;
    margin-bottom: 20px;
  }
  
  .add-file-button {
    margin-top: 20px;
  }
  
  .file-upload-wrapper {
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .success-message {
    color: #28a745;
    font-weight: bold;
    margin-top: 10px;
  }