.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border: 1px solid #130090;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 5px !important;
  background-color: #130090 !important; 
  border-radius: 5px !important;
}

.loading-circle {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db; 
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block; 
  margin-left: 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.progress-percentage {
  margin-top: 5px;
  font-weight: bold;
}

/* New styles for buttons, input fields, and image/video fields */
.submit-button {
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding for buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color transition */
}

/* New hover effect for submit button */
.submit-button:hover {
  background-color: white; /* Change background to white on hover */
  color: #130090; /* Change text color to match the original background */
}

.cancel-button {
  background-color: red; /* Change cancel button to red */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 15px; /* Padding for buttons */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color transition */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
}

.cancel-button:hover {
  background-color: darkred; /* Darker shade on hover for cancel button */
}

/* Disable hover effect when uploading */
.uploading .submit-button {
  background-color: #130090; /* Keep the original color when uploading */
  color: white; /* Keep text color white when uploading */
}

.uploading .cancel-button:hover {
  background-color: red; /* Keep the original color when uploading */
}

.form-input, .file-input {
  width: 100%; /* Full width */
  padding: 5px; /* Padding for input fields */
  border: 1px solid #ccc; /* Light border */
  border-radius: 5px; /* Rounded corners */
  margin-top: 5px; /* Space above input fields */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}


.thumbnail-preview-container {
  display: flex;
  align-items: center;
  gap: 10px; 
}

.thumbnail-preview {
  position: relative;
}

.thumbnail-preview img {
  max-width: 150px;
  max-height: 100px;
  object-fit: cover; 
  border-radius: 5px; 
}

.remove-thumbnail-button {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  font-size: 10px; 
  color: white;
}

.thumbnail-input-wrapper {
  flex-shrink: 0; 
  width: 60%; 
}

.thumbnail-preview-wrapper {
  flex-shrink: 0; 
}

.uploader-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.remove-uploader-button {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 1.2em;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
}

.remove-uploader-button:hover {
  background-color: #dc354511;
}

.video-uploader {
  position: relative;
}

.upload-success {
  text-align: center;
  animation: fadeIn 0.5s ease-in;
}

.success-icon {
  color: #4CAF50;
  font-size: 48px;
  animation: scaleIn 0.5s ease-out;
}

.success-message {
  color: #130090;
  font-size: 1.2em;
  padding-top: 10px;
  font-weight: 500;
  animation: slideUp 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { 
    transform: scale(0); 
    opacity: 0;
  }
  to { 
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.resume-upload-section {
  margin: 10px 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.resume-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
}

.resume-button:hover {
  background-color: #218838;
}

.pause-resume-button {
  padding: 8px 16px;
  margin-left: 10px;
  background-color: #f0ad4e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pause-resume-button:hover {
  background-color: #ec971f;
}

.pause-resume-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Update the label styles to be scoped to VideoUploader */
.video-uploader label {
  font-weight: bold;
}

/* Show asterisk only for required fields in VideoUploader */
.video-uploader label::after {
  content: " *";
  color: red;
}

/* Remove asterisk for optional fields in VideoUploader */
.video-uploader label[for="thumbnail"]::after,
.video-uploader label[for="isYoutubeVideo"]::after {
  content: none;
}

/* Remove unused styles */
.uploads-list,
.upload-item,
.upload-info,
.filename,
.status,
.progress-fill,
.upload-actions,
.start-button,
.resume-button,
.resume-upload-section,
.pause-resume-button {
  display: none;  /* These can be safely removed as they're not being used */
}

.form-group {
  margin-bottom: 10px; /* Add consistent spacing between form groups */
}

.form-actions {
  margin-top: 30px; /* Add extra space before the action buttons */
  display: flex;
  gap: 10px; /* Add space between buttons */
}

.cancel-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px; /* Match the submit button's border radius */
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin-right: 8px;
  cursor: pointer;
}

/* Container styles for both thumbnail and video */
.thumbnail-preview-container,
.video-preview-container {
  display: flex;
  align-items: center;
  gap: 10px; 
}

/* Input wrapper styles */
.thumbnail-input-wrapper,
.video-input-wrapper {
  flex-shrink: 0; 
  width: 60%; 
}

/* Preview styles */
.thumbnail-preview,
.video-preview {
  position: relative;
}

.thumbnail-preview img,
.video-preview img {
  max-width: 150px;
  max-height: 100px;
  object-fit: cover; 
  border-radius: 5px; 
}

.remove-thumbnail-button {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  font-size: 10px; 
  color: white;
}

/* Video preview play button styles */
.video-preview {
  position: relative;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}


.play-button-overlay::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent white;
  margin-left: 4px;
}